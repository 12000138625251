<template>
  <div>
    <div class="transaction__filter--container pb-1">
      <div class="row mx-0 justify-content-between w-100 flex-lg-row flex-column-reverse">
        <b-col cols="12" md="12" lg="6">
          <b-button-group>
            <b-button v-for="(item, index) in menus" :key="`${item}-${index}`" variant="primary"
              :class="{ active: item.value == filters }" @click="
                $router.push({ name: item.url, query: { q: item.value } })
                ">
              {{ item.name }}
            </b-button>
          </b-button-group>
        </b-col>
        <b-col cols="12" md="12" :lg="filters === 'produk' ? '6' : '4'"
          class="d-flex justify-content-end search__export" style="gap: 1rem">
          <b-button variant="primary" size="sm" @click="$bvModal.show(`${filterMenus.length && filterMenus[0].id}`)">
            <span>{{
              filterMenus.length && `Tambah ${filterMenus[0].name}`
            }}</span>
          </b-button>
          <b-button variant="success" size="sm" v-if="filters === 'produk'" @click="$bvModal.show('modal-sku')">
            <span>Upload SKU</span>
          </b-button>
          <b-button variant="info" size="sm" @click="$router.push({ name: 'master.product.bulk-link' })">
            <span>{{
              filterMenus.length && `Hubungkan ${filterMenus[0].name}`
            }}</span>
          </b-button>
        </b-col>
      </div>
    </div>
  </div>
</template>

<script>
import { BCol, BButton, BButtonGroup, VBToggle } from "bootstrap-vue";
import HeaderPage from "@/components/HeaderPage.vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";

export default {
  components: {
    BCol,
    BButton,
    BButtonGroup,
    HeaderPage,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      filters: "produk",
      menus: [
        {
          name: "Produk",
          id: "modal-produk",
          value: "produk",
          url: "master.product.index",
        },
        {
          name: "Barang",
          id: "modal-item",
          value: "barang",
          url: "master-barang.index",
        },
        {
          name: "Brand",
          id: "modal-brand",
          value: "brand",
          url: "master-brand.index",
        },
        {
          name: "Sub Brand",
          id: "modal-brand",
          value: "sub_brand",
          url: "master-brand.index",
        },
        {
          name: "Satuan",
          id: "modal-satuan",
          value: "satuan",
          url: "master-satuan.index",
        },
      ],
    };
  },
  computed: {
    filterMenus() {
      return this.menus.filter((e) => e.value === this.filters);
    },
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  watch: {
    "$route.query.q": function (value) {
      if (value) {
        this.filters = value;
        // console.log(this.filters, 'ADA GAAA ?');
      }
    },
  },
  mounted() {
    this.filters = this.$route.query.q || "produk";
  },
  created() { },
  methods: {},
};
</script>
