<template>
  <b-modal id="modal-satuan" size="lg" title="Informasi Satuan">
    <b-row>
      <!-- Nama Satuan -->
      <b-col cols="12" md="12" class="mb-2 custom__form--input">
        <label for="v-nama-satuan">Nama Satuan <span class="text-danger">*</span></label>
        <b-form-group>
          <b-form-input id="v-nama-satuan" v-model="formPayload.name" type="text" placeholder="Nama Satuan"
            class="custom__input" />
        </b-form-group>
      </b-col>
      <!-- Deskripsi Satuan -->
      <b-col cols="12" md="12" class="mb-3 custom__form--input">
        <label for="v-deskripsi">Deskripsi Satuan</label>
        <b-form-group>
          <b-form-textarea id="v-deskripsi" v-model="formPayload.description" rows="5" placeholder="Deskripsi Satuan"
            class="custom__textarea" />
        </b-form-group>
      </b-col>
    </b-row>
    <template #modal-footer="{}">
      <b-button variant="primary" @click="editUuid == null ? createItem() : updateItem()">
        Simpan
      </b-button>
    </template>
  </b-modal>
</template>
<script>
/* eslint-disable vue/require-default-prop */
import {
  BModal, BContainer, BRow, BCol, BButton, BFormGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BContainer,
  },
  props: {
    formData: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: '',
    },
    createItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    editId: {
      type: String,
    },
    updateItem: {
      type: Function,
    },
    cleanUpForm: {
      type: Function,
    },
  },
  watch: {
    editId(value) {
      this.editUuid = value
    },
    formData(value) {
      this.formPayload = value
    },
    formPayload: {
      handler(value) {
        this.$emit('getPayload', value)
      },
      deep: true,
    },
  },
  data() {
    return {
      formPayload: {
        name: '',
        description: '',
      },
      editUuid: null,
    }
  },
  methods: {
    directPage() {
      this.cleanUpForm()
      this.$bvModal.hide('modal-satuan')
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
</style>
