<template>
  <div class="section">

    <b-card-code no-body>
      <div class="table-responsive-sm" style="overflow-x: auto">
        <table class="table b-table table-fixed">
          <thead>
            <tr>
              <th v-for="column, index in columns" :key="index">{{ column.label }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item, index in result.data" :key="`table-${index}`">
              <td>{{ item.formatted_id || '-' }}</td>
              <td>{{ item.name || '-' }}</td>
              <td>{{ item.description || '-' }}</td>
              <td>
                <div class="btn-group" role="group" aria-label="Basic example">
                  <b-button class="border" size="sm" variant="danger" @click="deleteData(item.uuid)">
                    <feather-icon size="18" class="text-danger" icon="Trash2Icon" style="filter: brightness(5);" />
                  </b-button>
                  <b-button variant="primary" size="sm" @click="editItem(item)">
                    <b-img :src="require('@/assets/images/icons/Process2.svg')" style="filter: brightness(5);" /> Ubah
                  </b-button>
                </div>
              </td>
            </tr>
            <tr v-if="result.total == 0">
              <td colspan="5" class="text-center">
                Data is empty.
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row mt-1">
          <div class="col-6 pl-2">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col-6 pr-2">
            <pagination :data="result" :limit="4" align="right" @pagination-change-page="getData" />
          </div>
        </div>
      </div>
    </b-card-code>

  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import {
  BRow, BCol, BFormSelect, VBToggle, BImg, BButton,
} from 'bootstrap-vue'

// import ModalAddProductParent from '@/components/MasterProduct/Modal/ModalAddProductParent.vue'

import { VueGoodTable } from 'vue-good-table'
import BCardCode from '@/@core/components/b-card-code/BCardCode.vue'

export default {
  components: {
    VueGoodTable,
    BFormSelect,
    // ModalAddProductParent,
    BImg,
    BButton,
    BRow,
    BCol,
    BCardCode,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    result: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: '',
    },
    deleteData: {
      type: Function,
    },
    getData: {
      type: Function,
    },
    createItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    editId: {
      type: String,
    },
  },
  data() {
    return {
      progressValue: 35,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'ID',
          field: 'formatted_id',
        },
        {
          label: 'Nama Satuan',
          field: 'name',
        },
        {
          label: 'Deskripsi Satuan',
          field: 'description',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      items: [],
      search: '',
      filter: {
        search: '',
        sort_type: 'desc',
        per_page: 10,
      },
    }
  },
  watch: {
    filter: {
      handler(value) {
        this.$emit('filter', value)
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

.vgt-responsive {
  height: calc(100vh - 202px) !important;
  background-color: #fff;
}
</style>
