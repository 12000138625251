<template>
  <div class="section">
    <header-master-produk />
    <TableSatuan :result="result" :delete-data="deleteUom" :get-data="getUom" :edit-item="editItem"
      @filter="getFilter" />

    <ModalSatuan :form-data="formPayload" :create-item="createItem" :edit-id="editId" :edit-item="editItem"
      :update-item="updateItem" :clean-up-form="cleanUpForm" @getPayload="getPayload" />

  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import { mapActions, mapState } from 'vuex'
import {
  VBToggle,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import ModalSatuan from '@/components/Master/Satuan/Modal/ModalSatuan.vue'
import TableSatuan from '@/components/Master/Satuan/TableSatuan.vue'
import HeaderMasterProduk from '@/components/Master/HeaderMasterProduk.vue'

export default {
  title() {
    return 'Master Satuan'
  },
  components: {
    ModalSatuan,
    TableSatuan,
    HeaderMasterProduk,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      currentPage: 1,
      isLoading: true,
      result: {},
      validations: [],
      editId: null,
      formPayload: {
        name: '',
        description: '',
      },
      filter: {
        search: '',
        per_page: 10,
        sort_type: 'desc',
      },
    }
  },
  computed: {
    ...mapState({
      uoms: state => state.uom.uoms,
    }),
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getUom()
      }, 300),
      deep: true,
    },
  },
  created() {
    this.getUom()
  },
  methods: {
    ...mapActions('uom', ['getData', 'postData', 'deleteData', 'showData']),
    getPayload(value) {
      this.formPayload = value
    },
    getFilter(value) {
      this.filter = value
    },
    cleanUpForm() {
      this.editId = null
      this.iconBank = null
      this.formPayload = {
        name: '',
        description: '',
        logo: '',
      }
      this.validations = ''
    },
    editItem(item) {
      this.cleanUpForm()
      this.editId = item.uuid
      this.iconBank = item.logo_url
      this.$store.commit('uom/SET_FORM_PAYLOAD', this.formPayload = {
        name: item.name,
        description: item.description,
      })
      this.$bvModal.show('modal-satuan')
    },
    async getUom(page = 1) {
      this.isLoading = true
      const queryParams = this.filter
      queryParams.page = page
      await this.getData({
        params: queryParams,
      })
      this.result = this.uoms
      this.currentPage = this.uoms.current_page
      this.isLoading = false
      // this.isLoading = true
      // const queryParams = this.filter
      // queryParams.page = page
      // this.$store.dispatch('uom/getData', { uuid: '', params: queryParams }).then(result => {
      //   this.isLoading = false
      //   this.result = result.data
      //   this.currentPage = result.data.current_page
      // }).catch(err => {
      //   // eslint-disable-next-line no-console
      //   console.log(err)
      // })
    },
    async createItem() {
      const form = this.preparePayload()
      this.isLoading = true
      const response = await this.postData({
        id: '',
        payload: form,
      })
      if (response) {
        this.isLoading = false
        this.$bvModal.hide('modal-satuan')
        this.getUom()
        successNotification(this, 'Success', 'Satuan berhasil di buat')
        this.cleanUpForm()
      }
      // this.$store.dispatch('uom/postData', {
      //   query: '',
      //   payload: form,
      // })
      //   .then(() => {
      //     this.getData()
      //     successNotification(this, 'Success', 'Satuan berhasil di buat')
      //     this.cleanUpForm()
      //     this.$bvModal.hide('modal-satuan')
      //     this.isLoading = false
      //   })
      //   .catch(error => {
      //     if (error.response.data.meta.validations) {
      //       this.validations = error.response.data.meta.validations
      //     }
      //     this.isLoading = false
      //   })
    },
    async updateItem() {
      const form = this.preparePayload()
      this.isLoading = true
      form.append('_method', 'PATCH')

      const response = await this.postData({
        id: this.editId,
        payload: form,
      })
      if (response) {
        this.getUom()
        successNotification(this, 'Success', 'Satuan berhasil di ubah!')
        this.cleanUpForm()
        this.isLoading = false
        this.$bvModal.hide('modal-satuan')
      }
      // this.$store.dispatch('uom/postData', {
      //   query: `/${this.editId}`,
      //   payload: form,
      // })
      //   .then(() => {
      //     this.getData()
      //     successNotification(this, 'Success', 'Satuan berhasil di ubah!')
      //     this.cleanUpForm()
      //     this.isLoading = false
      //     this.$bvModal.hide('modal-satuan')
      //   })
      //   .catch(error => {
      //     if (error.response.data.meta.validations) {
      //       this.validations = error.response.data.meta.validations
      //       errorNotification(this, 'Oops!', error.response.data.meta.messages)
      //     }
      //     this.isLoading = false
      //   })
    },
    async deleteUom(uuid) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to delete this satuan?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const response = await this.deleteData({
            id: uuid,
          })
          this.getUom()
          this.$swal({
            icon: 'success',
            title: 'Success!',
            text: 'Satuan berhasil dihapus',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          // this.$store.dispatch('uom/deleteData', `/${uuid}`)
          //   .then(() => {
          //     this.getData()
          //     this.$swal({
          //       icon: 'success',
          //       title: 'Success!',
          //       text: 'Satuan berhasil dihapus',
          //       customClass: {
          //         confirmButton: 'btn btn-success',
          //       },
          //     })
          //   })
          //   .catch(error => {
          //     if (error.response.data.meta.messages.length > 0) {
          //       errorNotification(this, 'Oops!', error.response.data.meta.messages)
          //     }
          //   })
        }
      })
    },
    preparePayload() {
      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          form.append(key, this.formPayload[key])
        }
      }
      return form
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

.px-33 {
  padding-left: 33px;
  padding-right: 33px;
}

.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-18 {
  padding-top: 18px;
  padding-bottom: 18px;
}

#app {
  background: #f6f7fa;
}

.vgt-responsive {
  height: calc(100vh - 200px) !important;
  background-color: #fff;
}
</style>
